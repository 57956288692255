import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import ReportPlaceholder from "./ReportPlaceholder";
import SingleQuestion from "./SingleQuestion";

const TestReportPage = () => {
  const { reportId } = useParams();
  const [report, setReport] = useState();
  const { currentUser } = useAuth();
  const [selectedOption, setSelectedOption] = useState("All Questions");
  const [currentPage, setCurrentPage] = useState(1);
  const questionsPerPage = 10;
  const [error, setError] = useState(null);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleSelectedOptionChange = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedOption]);

  useEffect(() => {
    // Fetch the API URL from environment variables
    const apiUrl = process.env.REACT_APP_API_URL;

    const fetchMyReport = async () => {
      try {
        // Retrieve the currentUser token

        if (currentUser) {
          const token = await currentUser.getIdToken();

          // Set the authorization header
          const payloadHeader = {
            headers: {
              Authorization: "Bearer " + token,
            },
          };

          // Make a request to /tests/my-reports
          const response = await fetch(
            `${apiUrl}testseries/test-report/${reportId}`,
            payloadHeader
          );

          if (!response.ok) {
            // Handle non-successful response (e.g., 404, 500, etc.)
            if (response.status === 404) {
              setError("Report not found.");
            } else {
              setError("Error fetching report. Please try again later.");
            }
          } else {
            const data = await response.json();
            setReport(data);
          }
        }
      } catch (error) {
        console.log(error);
        setError("Error fetching report. Please try again later.");
      }
    };

    fetchMyReport();
  }, []);

  if (error) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="alert alert-danger mt-3" role="alert">
              {error}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!report) {
    return <ReportPlaceholder />;
  }

  const questions = report.test.sections.reduce((all, section) => {
    const questionsWithSectionInfo = section.questions.map(
      (question, index) => ({
        ...question,
        sectionName: section.name,
        questionNumber: index + 1, // Adding 1 to index to get the 1-based question number
      })
    );
    return all.concat(questionsWithSectionInfo);
  }, []);

  const getCorrectOptionId = (options) => {
    const correctOption = options.find((option) => option.correct);
    return correctOption ? correctOption._id : null;
  };

  const questionsWithAnswers = questions.map((question) => {
    const answerEntry = report.answer_map.find(
      (entry) => entry.question === question._id
    );

    if (!answerEntry) {
      return {
        ...question,
        answer: "Unanswered",
      };
    }

    const selectedOptionId = answerEntry.selected_option;
    const correctOptionId = getCorrectOptionId(question.options);

    const isCorrect = selectedOptionId === correctOptionId;
    return {
      ...question,
      answer: answerEntry
        ? isCorrect
          ? "Correct"
          : "Incorrect"
        : "Unanswered",
      selectedOptionId,
    };
  });

  const filteredQuestions = questionsWithAnswers.filter((question) => {
    if (selectedOption === "All Questions") {
      return true;
    } else if (selectedOption === "Correct") {
      return question.answer === "Correct";
    } else if (selectedOption === "Incorrect") {
      return question.answer === "Incorrect";
    } else if (selectedOption === "Unanswered") {
      return question.answer === "Unanswered";
    }
  });

  // Get the questions for the current page
  const indexOfLastQuestion = currentPage * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = filteredQuestions.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );

  return (
    <>
      <section className="py-5">
        {error && (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="alert alert-danger mt-3" role="alert">
                  {error}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <h3 className="mb-4 text-success">Test Overview</h3>
              <div className="card sticky-top">
                <div className="card-body">
                  <div className="text-center">
                    <img
                      src="/assets/icons/trophy.svg"
                      className="img mt-3 mb-3"
                      alt="Trophy"
                    ></img>
                    <h5>Congratulation !</h5>
                    <p>
                      <span className="fw-light">you have scored </span>
                      <span
                        className=" fw-bold"
                        style={{ fontSize: "20px", color: "#4bb543" }}
                      >
                        {report.score.toFixed(2)} / {questions.length * 2}
                      </span>
                    </p>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex justify-content-around">
                        <div>
                          <div className="d-flex align-items-center">
                            <img
                              src="/assets/icons/qa.svg"
                              className="img-fluid"
                              width="40px"
                              alt="total questions"
                            ></img>
                            <h4 className="ms-2">{questions.length}</h4>
                          </div>
                          <p
                            className="text-center mt-2 text-secondary"
                            style={{ fontSize: "20px" }}
                          >
                            Total Ques
                          </p>
                        </div>

                        <div>
                          <div className="d-flex align-items-center">
                            <img
                              src="/assets/icons/Checkmark.svg"
                              className="img-fluid"
                              width="40px"
                              alt="correct questions"
                            ></img>
                            <h4 className="ms-2">{report.correct}</h4>
                          </div>
                          <p
                            className="text-center mt-2 text-secondary"
                            style={{ fontSize: "20px" }}
                          >
                            Correct
                          </p>
                        </div>

                        <div>
                          <div className="d-flex align-items-center">
                            <img
                              src="/assets/icons/cross.svg"
                              className="img-fluid"
                              width="40px"
                              alt="wrong questions"
                            ></img>
                            <h4 className="ms-2">{report.wrong}</h4>
                          </div>
                          <p
                            className="text-center mt-2 text-secondary"
                            style={{ fontSize: "20px" }}
                          >
                            Wrong
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-around mt-4">
                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/icons/target.svg"
                          className="img-fluid"
                          width="40px"
                          alt="accuracy"
                        ></img>
                        <h4 className="ms-2">
                          {(
                            (report.correct / (report.correct + report.wrong)) *
                            100
                          ).toFixed(2)}
                          %
                        </h4>
                      </div>
                      <p
                        className="text-center mt-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        Accuracy
                      </p>
                    </div>

                    <div>
                      <div className="d-flex align-items-center">
                        <img
                          src="/assets/icons/clock.svg"
                          className="img-fluid"
                          width="40px"
                          alt="time taken"
                        ></img>
                        <h4 className="ms-2">
                          {formatTime(report.time_taken)}
                        </h4>
                      </div>
                      <p
                        className="text-center mt-2 text-secondary"
                        style={{ fontSize: "20px" }}
                      >
                        Time Taken
                      </p>
                    </div>
                  </div>

                  <center>
                    <a
                      className="btn btn-success"
                      href={
                        "/test-attempt/" +
                        report.testseries +
                        "/" +
                        report.test._id
                      }
                    >
                      Attempt Again
                    </a>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="d-flex justify-content-between">
                <h3 className="mb-4 text-success">{report.test.name}</h3>

                <button
                  type="button"
                  className="btn btn-success dropdown-toggle mb-3"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {selectedOption}
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        handleSelectedOptionChange("All Questions")
                      }
                    >
                      All Questions
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleSelectedOptionChange("Correct")}
                    >
                      Correct
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleSelectedOptionChange("Incorrect")}
                    >
                      Incorrect
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => handleSelectedOptionChange("Unanswered")}
                    >
                      Unanswered
                    </button>
                  </li>
                </ul>
              </div>

              {currentQuestions.length === 0 ? (
                <p>There are no {selectedOption} questions</p>
              ) : (
                currentQuestions.map((question) => (
                  <SingleQuestion
                    key={question.questionId}
                    question={question}
                  />
                ))
              )}

              <button
                type="button"
                className="btn btn-dark me-2"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              <button
                type="button"
                className="btn btn-dark"
                onClick={handleNextPage}
                disabled={currentQuestions.length < questionsPerPage}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestReportPage;
