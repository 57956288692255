import React, { useState } from "react";

const SingleQuestion = ({ question }) => {
  if (!question) {
    return (
      <div className="card bg-light mb-3">
        <div className="card-body">
          <h3 className="mb-2 placeholder-glow">
            Q{" "}
            <span
              className="placeholder col-12 placeholder-lg"
              style={{ width: "400px" }}
            ></span>
          </h3>

          <p className="placeholder-glow">
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
            <span className="placeholder col-12"></span>
          </p>

          <a className="btn btn-warning disabled placeholder col-4"></a>
        </div>
      </div>
    );
  }

  const renderOptionButton = (option, index, selectedOptionId) => {
    if (option.correct) {
      return (
        <div key={index}>
          <button
            type="button"
            className="btn mt-2 w-50 d-flex align-items-center btn-outline-success"
            disabled
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-circle-fill me-2"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <div dangerouslySetInnerHTML={{ __html: option.value }} />
          </button>
        </div>
      );
    }

    if (option._id === selectedOptionId) {
      return (
        <div key={index}>
          <button
            type="button"
            className="btn mt-2 w-50 text-start d-flex align-items-center btn-outline-danger"
            disabled
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-circle-fill me-2"
              viewBox="0 0 16 16"
            >
              <circle cx="8" cy="8" r="8" />
            </svg>
            <div dangerouslySetInnerHTML={{ __html: option.value }} />
          </button>
        </div>
      );
    } else {
      return (
        <div key={index}>
          <button
            type="button"
            className="btn mt-2 w-50 text-start d-flex align-items-center"
            disabled
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-circl me-2"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            </svg>
            <div dangerouslySetInnerHTML={{ __html: option.value }} />
          </button>
        </div>
      );
    }
  };

  console.log(question);

  return (
    <div className="card bg-light mb-3">
      <div className="card-body">
        {question.answer === "Unanswered" ? (
          <p>You didn't attempt this question</p>
        ) : question.answer === "Correct" ? (
          <p className="text-success">You answered this question correctly.</p>
        ) : question.answer === "Incorrect" ? (
          <p className="text-danger">
            Uh Oh! You answered this question incorrectly
          </p>
        ) : (
          <></>
        )}

        <h5>Q{question.questionNumber}. </h5>
        <small>{question.sectionName}</small>
        <h5 dangerouslySetInnerHTML={{ __html: question.statement }} />
        <small className="text-success">Options</small>
        {question.options.map((option, index) =>
          renderOptionButton(option, index, question.selectedOptionId)
        )}

        {question.explanation ? (
          <>
            <button
              type="button"
              className="btn btn-success mt-2"
              data-bs-toggle="collapse"
              data-bs-target={"#collapseExample" + question._id}
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                className="bi bi-key-fill me-2"
                viewBox="0 0 16 16"
              >
                <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
              See Explanation
            </button>

            <div
              className="collapse mt-2"
              id={"collapseExample" + question._id}
            >
              <div className="card card-body">
                <div
                  dangerouslySetInnerHTML={{ __html: question.explanation }}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SingleQuestion;
