import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const AccuracyChart = ({ data }) => {
  return (
    <div className="line-chart-card card shadow-sm">
      <div className="card-body">
        <h2>Accuracy Trend</h2>
        <div className="chart-container">
          {data.length > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={data}
                margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="accuracy" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccuracyChart;
