import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../Loader";
import AccuracyChart from "./AccuracyChart";
import ScoreChart from "./ScoreChart";
import SingleTestCard from "./SingleTestCard";

const TestSeriesPage = () => {
  const [testSeries, setTestSeries] = useState();
  const [progresses, setProgresses] = useState();
  const [activeTest, setActiveTest] = useState(null);

  const handleTestItemClick = (testId) => {
    setActiveTest(testId);
  };

  const { testSeriesId } = useParams();

  const { currentUser } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const testSeriesUrl =
      apiUrl + "testseries/singletestseries/" + testSeriesId;

    currentUser.getIdToken().then((token) => {
      const payloadHeader = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios
        .get(testSeriesUrl, payloadHeader)
        .then((response) => response.data)
        .then((fetchedTestSeries) => {
          setTestSeries(fetchedTestSeries);
        })
        .catch((err) => console.log(err));
    });

    const progressUrl =
      apiUrl + "testseries/test-series-progress/" + testSeriesId;

    currentUser.getIdToken().then((token) => {
      const payloadHeader = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios
        .get(progressUrl, payloadHeader)
        .then((response) => response.data)
        .then((fetchedProgress) => {
          setProgresses(fetchedProgress);
        })
        .catch((err) => console.log(err));
    });
  }, [testSeriesId, currentUser]);

  useEffect(() => {
    const url = apiUrl + "testseries/test-series-progress/" + testSeriesId;

    currentUser.getIdToken().then((token) => {
      const payloadHeader = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios
        .get(url, payloadHeader)
        .then((response) => response.data)
        .then((fetchedProgress) => {
          setProgresses(fetchedProgress);
        })
        .catch((err) => console.log(err));
    });
  }, []);

  if (!testSeries || !progresses) {
    return (
      <>
        <Loader></Loader>
      </>
    );
  }

  const filteredProgresses = progresses.filter(
    (progress) => progress.test._id === activeTest
  );

  const sortedProgresses = progresses
    .filter((progress) => progress.test._id === activeTest)
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  let scoreData = [];
  let accuracyData = [];

  sortedProgresses.forEach((progress) => {
    // Extract date and score for scoreArray
    scoreData.push({
      date: new Date(progress.createdAt).toLocaleDateString(),
      score: progress.score,
    });

    // Calculate accuracy for accuracyArray
    const accuracy =
      (progress.correct / (progress.correct + progress.wrong)) * 100;
    accuracyData.push({
      date: new Date(progress.createdAt).toLocaleDateString(),
      accuracy: parseInt(accuracy),
    });
  });

  return (
    <>
      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-sm-4">
              <div className="sticky-top">
                <h4>{testSeries.name}</h4>
                <small>All Tests</small>

                <div className="list-group mt-3">
                  {testSeries.tests.map((test) => (
                    <button
                      type="button"
                      className={`list-group-item list-group-item-action rounded-3 py-3 ${
                        activeTest === test._id ? "active" : ""
                      }`}
                      onClick={() => handleTestItemClick(test._id)}
                      key={test._id}
                    >
                      {test.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="col-sm-8">
              {activeTest ? (
                <>
                  <div className="row">
                    <div className="col-sm-6">
                      <ScoreChart data={scoreData} />
                    </div>

                    <div className="col-sm-6">
                      <AccuracyChart data={accuracyData} />
                    </div>
                  </div>
                  <center>
                    <a
                      href={
                        activeTest
                          ? "/test-attempt/" + testSeriesId + "/" + activeTest
                          : ""
                      }
                      type="button"
                      className="btn btn-outline-success mt-4 w-100"
                    >
                      {filteredProgresses.length === 0
                        ? "Attempt Now"
                        : "Attempt Again"}
                    </a>
                  </center>
                </>
              ) : (
                <>
                  <div
                    className="modal modal-sheet position-static d-block p-4 py-md-5"
                    tabIndex="-1"
                    role="dialog"
                    id="modalTour"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content rounded-4 shadow">
                        <div className="modal-body p-5">
                          <h2 className="fw-bold mb-0">
                            Please select a Test to see
                          </h2>

                          <ul className="d-grid gap-4 my-5 list-unstyled small">
                            <li className="d-flex gap-4">
                              <div>
                                <h5 className="mb-0">
                                  Detailed Test Analytics
                                </h5>
                              </div>
                            </li>
                            <li className="d-flex gap-4">
                              <div>
                                <h5 className="mb-0">Previous Reports</h5>
                              </div>
                            </li>
                            <li className="d-flex gap-4">
                              <div>
                                <h5 className="mb-0">
                                  Score and Accuracy Trends
                                </h5>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-sm-12 mb-4">
                  {filteredProgresses.length === 0 ? (
                    activeTest ? (
                      <>
                        <p className="text-body-emphasis text-center">
                          You have not attempted this Test
                        </p>
                      </>
                    ) : (
                      <> </>
                    )
                  ) : (
                    <>
                      <h3 className="mt-5 mb-3">Previous Attempts</h3>
                      {filteredProgresses.map((progress) => (
                        <SingleTestCard report={progress} key={progress._id} />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestSeriesPage;
