import React from "react";

const ReportTile = ({ report }) => {
  if (!report) {
    return (
      <>
        <div className="card mt-3">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div style={{ width: "40%" }}>
                <h5 class="card-title placeholder-glow">
                  <span class="placeholder col-12"></span>
                </h5>

                <p class="card-text placeholder-glow">
                  <span class="placeholder col-8"></span>
                </p>
              </div>
              <div>
                <small className="d-inline-flex mb-3 px-2 py-1 fw-semibold text-secondary-emphasis bg-secondary-subtle border border-secondary-subtle rounded-2">
                  Score :
                </small>
              </div>
              <a className="btn btn-outline-success">Check Full Report</a>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="card mt-3">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4>{report.test.name}</h4>
            <p>
              Attempted At -{" "}
              {new Date(report.test.createdAt).toLocaleString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              })}
            </p>
          </div>
          <div>
            <small className="d-inline-flex mb-3 px-2 py-1 fw-semibold text-secondary-emphasis bg-secondary-subtle border border-secondary-subtle rounded-2">
              Score : {report.score}
            </small>
          </div>
          <a
            href={"/test-report/" + report._id}
            className="btn btn-outline-success stretched-link"
          >
            Check Full Report
          </a>
        </div>
      </div>
    </div>
  );
};

export default ReportTile;
