import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "./contexts/AuthContext";

const Navbar = () => {
  const { currentUser } = useAuth();
  const [show, setShow] = useState(false);
  const { logout } = useAuth();
  const [profile, setProfile] = useState();

  const handleClose = () => {
    setShow(false);
  };

  const handleLogout = () => {
    logout();
    setShow(false);
    window.location = "/";
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    currentUser &&
      currentUser.getIdToken().then((token) => {
        const payloadHeader = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        axios
          .get(apiUrl + "student/profile", payloadHeader)
          .then((response) => response.data)
          .then((profile) => {
            console.log(profile);
            setProfile(profile);
          })
          .catch((err) => console.log(err));
      });
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <a className="navbar-brand ms-3" href="/">
          <img src="/assets/images/logo.png" alt="Bootstrap" width="80" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">
                Dashboard
              </a>
            </li>
          </ul>
          {currentUser ? (
            <>
              {profile ? (
                <span className="navbar-text me-3">{profile.name}</span>
              ) : (
                <> </>
              )}

              <span className="navbar-text me-3">
                {currentUser.phoneNumber}
              </span>

              <button
                className="btn btn-danger text-white me-3"
                onClick={handleShow}
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <a className="btn btn-danger text-white me-3" href="/login">
                Login
              </a>
            </>
          )}
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to logout?</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Yes, Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </nav>
  );
};

export default Navbar;
