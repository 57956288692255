import React, { useState, useEffect } from "react";

const SingleSeriesCard = ({ testSeries, progresses }) => {
  if (!testSeries) {
    return (
      <>
        <div className="col">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">
                <p class="placeholder-glow">
                  <span class="placeholder col-12"></span>
                </p>
              </h5>
              <p class="placeholder-glow">
                <span class="placeholder col-12"></span>
              </p>
              <p class="placeholder-glow">
                <span class="placeholder col-12"></span>
              </p>
              <p class="placeholder-glow">
                <span class="placeholder col-12"></span>
              </p>
              <a type="button" className="btn btn-success w-100">
                See All Tests
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }

  const uniqueTests = new Set(progresses.map((progress) => progress.test));

  return (
    <>
      <div className="col">
        <div className="card h-100">
          <div className="card-body">
            <h5 className="card-title">{testSeries.name}</h5>
            <p className="card-text">Total Tests = {testSeries.tests.length}</p>
            <p className="card-text text-success">
              Attempted Tests = {uniqueTests.size}
            </p>
            <p className="card-text text-warning">
              Pending Tests = {testSeries.tests.length - uniqueTests.size}
            </p>
            <a
              href={"/test-series/" + testSeries._id}
              type="button"
              className="btn btn-success w-100"
            >
              See All Tests
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSeriesCard;
