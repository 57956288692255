import React, { useEffect, useState } from "react";
import axios from "axios";

const Enrollments = () => {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [planFilter, setPlanFilter] = useState("all"); // Default filter is set to "all"
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch students here

    const token = localStorage.getItem("sales-token"); // Replace with your token storage mechanism
    const headers = { Authorization: token };

    axios
      .get(`${apiUrl}sales/all-enrollments`, {
        headers: headers,
        params: {
          distributorId: "64cdf3bdcbf428ce0215a933", // Replace with the actual distributorId
        },
      })
      .then((response) => {
        setStudents(response.data);
      })
      .catch((error) => {
        console.error("Error fetching students:", error);
      });
  }, [apiUrl]);

  if (!students) {
    return <></>;
  }

  const formatCreatedAt = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  const filteredStudents = students.filter((student) => {
    const nameMatch = student.studentname
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const phoneMatch = student.phone.toString().includes(searchTerm);
    let planMatch = true;

    if (planFilter === "demo") {
      planMatch = student.enrollments.length === 1;
    } else if (planFilter === "paid") {
      planMatch = student.enrollments.length > 1;
    }

    return (nameMatch || phoneMatch) && planMatch;
  });

  return (
    <div className="container mt-5">
      <h4 className="text-center">All Students</h4>
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by name or phone"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <select
          className="form-select"
          value={planFilter}
          onChange={(e) => setPlanFilter(e.target.value)}
        >
          <option value="all">All Plans</option>
          <option value="demo">Demo Plan</option>
          <option value="paid">Paid Plan</option>
        </select>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Student Name</th>
            <th>Mobile Number</th>
            <th>Test Series Enrolled</th>
            <th>Plan</th>
          </tr>
        </thead>
        <tbody>
          {filteredStudents.map((student) => (
            <tr key={student.studentname}>
              <td>{student.studentname}</td>
              <td>{student.phone}</td>
              <td>
                <ol className="list-group list-group-numbered">
                  {student.enrollments.map((enrollment) => (
                    <li
                      key={enrollment._id}
                      className="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">
                          {enrollment.testseriesname}
                        </div>
                        <b>Enrolled On : </b>
                        {formatCreatedAt(enrollment.createdAt)}
                      </div>
                    </li>
                  ))}
                </ol>
              </td>
              <td>
                {student.enrollments.length === 1 ? (
                  <h4>
                    <span className="badge text-bg-secondary">Demo</span>
                  </h4>
                ) : (
                  <h4>
                    <span className="badge text-bg-warning">Paid</span>
                  </h4>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Enrollments;
