import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";
import ReportTile from "./ReportTile";

const AttemptedTests = () => {
  const [recentReports, setRecentReports] = useState([]);
  const [message, setMessage] = useState();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { currentUser } = useAuth(); // Get the currentUser from the AuthContext

  useEffect(() => {
    if (!currentUser) return; // If currentUser is not available, return

    // Fetch recent tests data from the API
    const fetchRecentTests = async () => {
      try {
        const token = await currentUser.getIdToken();

        const payloadHeader = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        const response = await axios.get(
          apiUrl + "student/recent-tests",
          payloadHeader
        );
        const recentReportsData = response.data;
        if (recentReportsData.length === 0) {
          setMessage("You have not attempted any test!");
        }
        setRecentReports(recentReportsData);
      } catch (error) {
        console.error("Error fetching recent tests:", error);
      }
    };

    fetchRecentTests();
  }, [apiUrl, currentUser]);

  if (message) {
    return (
      <>
        <div className="mb-5">
          <p>You have not attempted any test yet!</p>
        </div>
      </>
    );
  }

  if (recentReports.length < 1) {
    return (
      <>
        <div className="mb-5">
          <ReportTile />
          <ReportTile />
        </div>
      </>
    );
  }

  console.log(recentReports);
  return (
    <div className="mb-5">
      {recentReports.map((report) => (
        <ReportTile key={report.id} report={report} />
      ))}
    </div>
  );
};

export default AttemptedTests;
