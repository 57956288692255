import React from "react";
import { Modal, Button, Table } from "react-bootstrap";

const ConfirmationModal = ({
  show,
  onHide,
  answerMap,
  timeLeft,
  submitTest,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to Submit the Test?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 class="sub-title">
          Once submitted, you will not be able to edit your answers
        </h6>
        <Table>
          <thead>
            <tr>
              <th scope="col">Attempted Questions</th>
              <th scope="col">Time Left</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{answerMap.size}</td>
              <td>
                {`${parseInt(timeLeft / 3600) % 60} Hours, ${
                  parseInt(timeLeft / 60) % 60
                } Minutes, ${timeLeft % 60} Seconds Left`}
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={submitTest}>
          Yes
        </Button>
        <Button variant="danger" onClick={onHide}>
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
