import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext"; // Import the useAuth hook
import Layout from "./components/Dashboard/Layout";
import Login from "./components/Login/Login";
import TestAttemptPage from "./components/TestAttempt/TestAttemptPage";
import TestReportPage from "./components/TestReport/TestReportPage";
import TestSeriesPage from "./components/TestSeriesDash/TestSeriesPage";
import LoginSales from "./components/Sales/LoginSales";
import Enrollments from "./components/Sales/Enrollments";

function App() {
  const { currentUser } = useAuth(); // Get the currentUser from useAuth

  return (
    <BrowserRouter>
      <Routes>
        {/* Redirect to login if currentUser is not defined */}
        {!currentUser && <Route path="/" element={<Navigate to="/login" />} />}
        {/* If the user is logged in, show the Layout */}
        {currentUser && <Route path="/" element={<Layout />} />}

        <Route path="/login" element={<Login />} />
        <Route path="/test-series/:testSeriesId" element={<TestSeriesPage />} />
        <Route path="/test-report/:reportId" element={<TestReportPage />} />

        <Route
          path="/test-attempt/:testSeriesId/:testId"
          element={<TestAttemptPage />}
        />

        {/* Sales routes */}
        <Route path="/admin/login" element={<LoginSales />} />
        <Route path="/enrollments" element={<Enrollments />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
