import React, { useState, useEffect } from "react";
import axios from "axios";
import SingleSeriesCard from "./SingleSeriesCard";
import { useAuth } from "../../contexts/AuthContext";

const TestSeriesCards = () => {
  const [enrolments, setEnrolments] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;
  const { currentUser } = useAuth();

  const fetchEnrolments = async () => {
    try {
      if (currentUser) {
        const token = await currentUser.getIdToken();

        const payloadHeader = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };

        const response = await axios.get(
          apiUrl + "student/testSeriesEnrolled",
          payloadHeader
        );
        const enrollments = response.data;
        setEnrolments(enrollments);
      }
    } catch (error) {
      console.error("Error fetching test series:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEnrolments();
  }, [currentUser]);

  if (loading) {
    // Display loading state with empty SingleSeriesCard components
    return (
      <div className="row row-cols-1 row-cols-md-4 g-4">
        <SingleSeriesCard />
        <SingleSeriesCard />
        <SingleSeriesCard />
        <SingleSeriesCard />
      </div>
    );
  }

  if (enrolments.length < 1) {
    return (
      <div class="alert alert-danger" role="alert">
        You are not enrolled in any Test Series
      </div>
    );
  }

  return (
    <div className="row row-cols-1 row-cols-md-4 g-4">
      {enrolments.map((enrolment) => (
        <SingleSeriesCard
          key={enrolment.id}
          testSeries={enrolment.testseries}
          progresses={enrolment.test_progress}
        />
      ))}
    </div>
  );
};

export default TestSeriesCards;
