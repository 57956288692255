import React from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { hydrate, render } from "react-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Navbar from "./Navbar";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate(
    <AuthProvider>
      <Navbar />
      <App />
    </AuthProvider>,
    rootElement
  );
} else {
  render(
    <AuthProvider>
      <Navbar />
      <App />
    </AuthProvider>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
