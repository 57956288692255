import React from "react";

const SingleTestCard = ({ report }) => {
  if (!report) {
    return (
      <>
        <div className="card mt-3">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="card-title placeholder-glow">
                  <span className="placeholder col-12"></span>
                </h4>
                <p className="placeholder-glow">
                  <span className="placeholder col-12"></span>
                </p>
                <p>Score : NaN Accuracy : NaN%</p>
              </div>

              <a
                className="btn btn-success disabled placeholder col-3"
                aria-disabled="true"
              ></a>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="card mt-3">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4>{report.test.name}</h4>
              <p>Attempted on - {report.createdAt}</p>
              <p>
                Score : <b>{report.score}/60 </b>
                Accuracy:
                <b>
                  {(report.correct / (report.correct + report.wrong)) * 100}%
                </b>
              </p>
            </div>

            <a
              href={"/test-report/" + report._id}
              type="button"
              className="btn btn-outline-success"
            >
              Check Full Report
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleTestCard;
