import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import auth from "../../firebase";

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(false);
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [otpError, setOTPError] = useState(false);
  const navigate = useNavigate();
  const otpInputsRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate("/");
    }
  }, [currentUser]);

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
    setError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (phoneNumber.length !== 10) {
      setError(true);
    } else {
      setLoading(true);
      sendOTP();
    }
  };

  const sendOTP = () => {
    // Perform logic to send OTP

    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, "+91" + phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).

        window.confirmationResult = confirmationResult;
        setOTP(["", "", "", "", "", ""]);
        setShowOTPForm(true);
        setLoading(false);
        // ...
      })
      .catch((error) => {});
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sendotp",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  }, []);

  const handleOTPSubmit = (e) => {
    e.preventDefault();
    const enteredOTP = otp.join("");
    setOtpLoading(true);
    window.confirmationResult
      .confirm(enteredOTP)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        auth.currentUser = user;
        setOtpLoading(false);
        // ...
      })
      .catch((error) => {
        setOTPError(error.toString());
        setOtpLoading(false);
      });
  };

  const handleOTPChange = (index, value, event) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (
      event.type === "keyup" &&
      value.length === 1 &&
      index < otp.length - 1
    ) {
      otpInputsRef.current[index + 1].focus();
    }
  };

  const handleOTPInputFocus = (index) => {
    otpInputsRef.current[index].select();
  };

  useEffect(() => {
    if (showOTPForm) {
      otpInputsRef.current[0].focus();
    }
  }, [showOTPForm]);

  const handleEditNumber = () => {
    setShowOTPForm(false);
    setPhoneNumber(phoneNumber);
  };

  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-3"></div>
          <div className="col-sm-6 text-center">
            <h2>CUET ( UG) Practice Tests</h2>
            <div className="d-flex justify-content-between align-items-center">
              <hr className="w-50" />
              <h4 className="text-center mx-3 lead">Login</h4>
              <hr className="w-50" />
            </div>

            {!showOTPForm ? (
              <form onSubmit={handleSubmit}>
                <div className="row g-2 mt-4">
                  <div className="col-1">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="IN"
                      value="IN"
                      disabled
                    />
                  </div>
                  <div className="col">
                    <div className="position-relative">
                      <input
                        type="text"
                        className={`form-control ${error ? "is-invalid" : ""}`}
                        placeholder="Mobile Number"
                        value={phoneNumber}
                        onChange={handleChange}
                      />
                      {error && (
                        <div className="invalid-feedback">
                          <i className="bi bi-exclamation-triangle-fill"></i>
                          Please enter a valid 10-digit number.
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn mt-4 w-100"
                  style={{ backgroundColor: "#017336", color: "white" }}
                  id="sendotp"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Sending OTP...
                    </>
                  ) : (
                    "Continue"
                  )}
                </button>
              </form>
            ) : (
              <form onSubmit={handleOTPSubmit}>
                <div className="mt-4">
                  <p>
                    We have sent an OTP to <strong>{phoneNumber}</strong>{" "}
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={handleEditNumber}
                      style={{ color: "#DB0001" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#DB0001"
                        className="bi bi-pencil-square"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                        <path
                          fillRule="evenodd"
                          d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                        />
                      </svg>{" "}
                      Edit
                    </button>
                  </p>
                </div>

                <div className="row g-2 mt-4">
                  {otp.map((digit, index) => (
                    <div className="col" key={index}>
                      <input
                        ref={(el) => (otpInputsRef.current[index] = el)}
                        type="text"
                        className={`form-control ${
                          otpError ? "is-invalid" : ""
                        }`}
                        maxLength={1}
                        value={digit}
                        onChange={(e) =>
                          handleOTPChange(index, e.target.value, e)
                        }
                        onKeyUp={(e) =>
                          handleOTPChange(index, e.target.value, e)
                        }
                        onFocus={() => handleOTPInputFocus(index)}
                      />
                    </div>
                  ))}
                </div>
                {otpError && (
                  <div className="mt-2 text-danger">
                    Incorrect OTP. Please try again.
                  </div>
                )}

                <button
                  type="submit"
                  className="btn mt-4 w-100 btn-success"
                  disabled={otpLoading}
                >
                  {otpLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Verifying OTP...
                    </>
                  ) : (
                    "Verify OTP"
                  )}
                </button>
              </form>
            )}
          </div>
          <div className="col-sm-3"></div>
        </div>
      </div>
    </section>
  );
};

export default Login;
