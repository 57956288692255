import React from "react";
import AttemptedTests from "./AttemptedTests";
import TestSeriesCards from "./TestSeriesCards";

const Layout = () => {
  return (
    <>
      <section>
        <div className="container mt-4">
          <div className="row">
            <div className="col-sm-12">
              <h2>You have enrolled in</h2>
            </div>
          </div>
          <TestSeriesCards />
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <div className="row">
            <div className="col-sm-12">
              <h2>Recently Attempted Tests</h2>
            </div>
          </div>

          <AttemptedTests />
        </div>
      </section>
    </>
  );
};

export default Layout;
