import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginSales = () => {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();

  const handleLoginSales = async (e) => {
    e.preventDefault();

    if (!validatePhone(phone)) {
      setPhoneError("Phone number must be 10 digits and numbers only");
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "sales/login",
        { phone, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const token = response.data.token;

        if (token) {
          localStorage.setItem("sales-token", token);
          navigate("/enrollments");
        } else {
          console.log("Token is missing in the response");
        }
      } else {
        setLoginError("Invalid phone or password");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setLoginError("An error occurred while logging in", error);
    }
  };

  const validatePhone = (phoneNumber) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(phoneNumber);
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">Admin Panel</div>
            <div className="card-body">
              {loginError ? <p>{loginError}</p> : <></>}
              <form onSubmit={handleLoginSales}>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label">
                    Phone
                  </label>
                  <input
                    type="text"
                    className={`form-control ${phoneError ? "is-invalid" : ""}`}
                    id="phone"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setPhoneError("");
                    }}
                    required
                  />
                  {phoneError && (
                    <div className="invalid-feedback">{phoneError}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginSales;
